import { Component, OnInit } from '@angular/core';
import { BindieApiService} from '../bindie-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Release } from '../release';
import {CampaignService} from "../campaign.service";
import {Campaign} from '../campaign';
import {Title} from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss']
})
export class ReleasesComponent implements OnInit {

  constructor(
    private biApi: BindieApiService,
    private route: ActivatedRoute,
    private campaignUtil : CampaignService,
    private title:Title,
    private meta:Meta,
    private router:Router,
  ){}

	errorMessage:string;
	campaignData : Campaign[] = [];
	closestStore : Campaign;
	release : Release;
	slug : string = '';
	links = [];
	campaignsProcessed:boolean = false;
	processingCampaigns:boolean=false;
	loaderColor:string='#E11A1A';
	year:string;
	
  getData(): void {

    //Get release data from DB...
    this.biApi.getRelease(this.slug)
    .subscribe(response => {
	      this.release = response;
	      //Set up title / meta info
	      this.title.setTitle(`Buy ${this.release.artist} - ${this.release.title} and Support Local Record Stores`);
	      this.meta.addTag({ name: 'description', content: `Buy ${this.release.artist} - ${this.release.title} and Support Local Record Stores` });

	      let campaignData = response.links;
	      this.campaignData = campaignData;

	      if(window.navigator['permissions']){
		      window.navigator['permissions'].query({name:'geolocation'}).then(result=> {
			      if (result.state == 'granted'){
				      this.processCampaigns(campaignData);
			      }
		      });
	      }
      },
    //TODO: Gacefully handle database error?
    err => {
	    console.log(err);
    });
  }
	getBackground(){
		let style=null;
		if(this.release.background_image){
			const isHex = /^#([0-9A-F]{3}){1,2}$/i.test(this.release.background_image);
			if(isHex){
				style = {'background-color':this.release.background_image};
			} else {
				style = {
					'backgroundRepeat':'no-repeat',
					'backgroundPosition':'center left',
					'backgroundAttachment':'fixed',
					'backgroundSize':'cover',
					'backgroundImage':'url(assets/images/'+this.release.background_image+')'
				}
			}
		}
		return style;
	}
  processCampaigns(campaigns?): void{
	  console.log("processing campaigns");
    if(!campaigns) campaigns = this.campaignData;
    this.processingCampaigns = true;
    this.campaignsProcessed = false;
    //Sort campaigns by browser client location
    this.campaignUtil.distanceSort(campaigns)
    .subscribe(sortedCampaigns => {
		console.log('distance sotred!!!!');
        this.processingCampaigns = false;
        this.campaignData = sortedCampaigns;
        this.closestStore = this.campaignData.shift();
        this.campaignsProcessed=true;
      },
      err=>{
        //user denied geolocation
        this.processingCampaigns = false;
        this.campaignData = campaigns;
        this.campaignsProcessed=true;
      });
  }

  ngOnInit(){
    this.slug = this.route.snapshot.paramMap.get('slug');
    if (this.slug == 'umo-sex-and-food') this.loaderColor= '#da8ecf';
    this.getData();
	var currentTime = new Date();
	this.year = currentTime.getFullYear()+"";
  }


}
