<div class="releases-list" *ngIf="releases.length">
	<div class="single-release" *ngFor="let release of releases">
		<a routerLink="/dashboard/releases/{{release.id}}" routerLinkActive="true">
			<img src="/assets/images/{{release['album-cover']}}" />
			<div class="release-details">
				<h3>{{release.artist}}</h3>
				<h4>{{release.title}}</h4>
			</div>
		</a>
	</div>
</div>
