<!--The content below is only a placeholder and can be replaced.-->
<div class="store-creator-form">
	<div class="form-group">
		<label>Store Name</label>
		<input
		  style="width:100%; height:40px;"
		  placeholder="enter store name"
		  autocorrect="off" autocapitalize="off"
		  spellcheck="off" type="text"
		  [(ngModel)]="storeName"
		  class="form-control">
	</div>
	<div class="form-group">
		<label>Location</label>
		<input
		style="width:100%; height:40px;"
		placeholder="Search by Address or Business"
		autocorrect="off" autocapitalize="off"
		spellcheck="off" type="text"
		class="form-control"
		#search
		[(formControl)]="searchControl">
	</div>
	<agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
		<agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
	</agm-map>
	<br>
	<button
		class="btn small"
		[disabled]="!storeLocation || !storeName || loading"
		(click)="createStore()">
		Create Store
	</button>
</div>
