import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter, map } from 'rxjs/operators'
import { ModalService } from '../../../modal.service';

@Component({
  selector: 'app-store-lookup',
  templateUrl: './store-lookup.component.html',
  styleUrls: ['./store-lookup.component.scss']
})
export class StoreLookupComponent implements OnInit {
    @Input() stores: any[];
	@Input() storeId?: number;
	@Input() modalId: string;
	@Output() selected: EventEmitter<any> = new EventEmitter();
	store:any;
	oldStore:any;
	storesBuffer:any[];
	bufferSize = 50;
    numberOfItemsFromEndBeforeFetchingMore = 10;
	newStore: any;
	loading:boolean;
	input$ = new Subject<string>();
	constructor(private modalService: ModalService) { }

	ngOnInit(): void {
		if(this.storeId){
			const thisStore = this.stores.filter(s=>s.id === this.storeId)[0];
			this.store =  thisStore;
			this.oldStore = thisStore;
		}
		this.onSearch();
	}

	private storeFilter(term, x){
		let storeName = x.label ? x.label.toLowerCase():null;
		return term ? storeName.includes(term.toLowerCase()) : false;
	}


	private fakeService(term) {
		return new Observable((observer) => {
			observer.next(this.stores.filter(x=>this.storeFilter(term,x)));
		});
	}

	fetchMore(term?:String) {
		const len = this.storesBuffer.length;
		this.loading = true;
		const more = this.stores.filter(x=>this.storeFilter(term,x));
		setTimeout(() => {
			this.loading = false;
			this.storesBuffer = this.storesBuffer.concat(more);
		}, 200)
	}

	onStoreCreated(store){
		//add new store in and set to model
		this.stores.push(store);
		this.store = store;
		this.onStoreChanged(store);
		this.closeStoreCreator();
	}

	onScrollToEnd() {
		this.fetchMore();
	}

	onSearch() {
		this.input$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap(term => this.fakeService(term))
		).subscribe((data:any[]) => {
			this.storesBuffer = data.slice(0, this.bufferSize);
		})
	}

	onStoreChanged(store){
		//Disable new store... enable old store...
		if(store) this.stores.filter(s => s.id === store.id)[0].disabled = true;
		if(this.oldStore) this.stores.filter(s => s.id === this.oldStore.id)[0].disabled = false;
		this.oldStore=store;
		this.selected.emit(store);
	}

	openStoreCreator(){
		this.modalService.open('store-creator-modal-'+this.modalId);
	}

	closeStoreCreator(){
		this.modalService.close('store-creator-modal-'+this.modalId);
	}
}
