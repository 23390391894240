import { Component, OnInit, Input} from '@angular/core';
import { Campaign } from "../campaign";


@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {

    @Input() campaigns: Campaign[];
    constructor() {}




  ngOnInit() {
  }

}
