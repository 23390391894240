<style>
    .campaign-list a{
        color:#da8ecf;
    }
    .campaign-list a:hover{
        color:#c77bbc
    }
</style>

<section class="secondary"  *ngIf="campaigns?.length > 0">

    <header>
      <h2>All Participating Record Stores</h2>
    </header>

    <div class="campaign-list">
      <div class="cft-g">
        <div *ngFor="let campaign of campaigns" class="cft-gi">
          <h3>{{campaign.name}}</h3>
          <h4>{{campaign.city}}, <span>{{campaign.state}}</span></h4>
          <a [href]="campaign.release_link">Buy at {{campaign.name}}</a>
        </div>
      </div>
    </div>

</section>