
import { Component }   from '@angular/core';
import { Router }      from '@angular/router';
import { AuthService } from '../auth.service';
import {UserService} from "../user.service";
import {CognitoUtil} from "../aws/cognito.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  message: string;

  constructor(private user: UserService, public authService: AuthService, public router: Router) {}

  formFeedback(message?:string) {
    if(!message) {
	    message = this.authService.isLoggedIn ? "" : "Incorrect username or password";
    }
    this.message = message;
  }

  login(form) {

    if(!form.valid){
	    this.formFeedback("Please Enter a Username and a Password");
	    return false;
    }


    this.authService.login(form.value.email, form.value.password).subscribe((data) => {
		console.log("LOGIN COMPONENT");
		console.log(data);
		console.log(this.authService.isLoggedIn());
      this.formFeedback();
      if (this.authService.isLoggedIn()) {
		  console.log("LOGGED IN!");
        // Get the redirect URL from our auth service
        // If no redirect has been set, use the default
        let redirect = this.authService.redirectUrl ? this.authService.redirectUrl : '/dashboard/releases';
        this.router.navigate([redirect]);
      }
    }, (err) => {
			this.formFeedback(err.error.message);
		});
  }

  logout() {
    this.authService.logout();
    this.formFeedback("You have logged out");
  }
}
