import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params }   from '@angular/router';
import { BindieApiService } from '../../bindie-api.service';
import { StoreCreatorComponent } from '../../store-creator/store-creator.component';
import { ModalService } from '../../modal.service';
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, filter, map } from 'rxjs/operators'

@Component({
  selector: 'app-dash-release-single',
  templateUrl: './dash-release-single.component.html',
  styleUrls: ['./dash-release-single.component.scss']
})

export class DashReleaseSingleComponent implements OnInit {
	release:any;
	stores:any[] = [];
	storesBuffer:any[];
	bufferSize = 50;
    numberOfItemsFromEndBeforeFetchingMore = 10;
	newStore: any;
	newStoreLink: string;
	releaseLoaded:boolean=false;
	addingReleaseLink:boolean;
	showAddNew:boolean=true
	loading:boolean;
	input$ = new Subject<string>();

	constructor(
		private route: ActivatedRoute,
		private biApi: BindieApiService,
		private modalService: ModalService
	) {}

	private storeLinkExists(store_id): boolean{
		const exists = this.release.links.filter((l)=>l.store_id===store_id);
		return !!exists.length;
	}

	getFormattedDate() {
		const d = new Date(this.release.store_announcement_date);
		return d.toLocaleDateString();
	}

	getStores(callback?:Function){
		this.biApi.getStores().subscribe(response => {
			response.forEach(store => {
				store.disabled = this.storeLinkExists(store.id);
			});
			this.stores=response;
			if(callback) callback(response);
 		});
	}

	addReleaseLink(){
		this.addingReleaseLink = true;
		this.biApi.addReleaseLink(this.release.id, this.newStore.id, this.newStoreLink)
		.subscribe(response => {
			this.addingReleaseLink = false;
			this.release.links.unshift({...response, ...this.newStore});
			this.resetNewStoreForm();
			this.showAddNew=false;
			setTimeout(() => {
				this.showAddNew=true;
			}, 1);
		});
	}

	resetNewStoreForm(){
		this.newStore = {};
		this.newStoreLink="";
	}

	onStoreCreated(store){
		this.getStores(()=>{
			const selected = this.stores.filter(s=>s.id===store.id);
			this.newStore = selected[0];
			this.closeStoreCreator();
		});
	}
	onStoreSelected(store){
		this.newStore=store;
	}

	openStoreCreator(){
		this.modalService.open('store-creator-modal');
	}
	closeStoreCreator(){
		this.modalService.close('store-creator-modal');
	}

	onSavedLink(link){
		const edits = {release_link:link.release_link, store_id:link.store_id};
		this.biApi.editReleaseLink(this.release.id, link.id, edits)
		.subscribe(response => {
			//console.log(response);
 		});
	}
	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.biApi.getMyRelease(params.id).subscribe(response => {
				response.links = response.links.reverse();
				this.release = response;
				this.releaseLoaded=true;
				this.getStores();
			});
		});

	}

}
