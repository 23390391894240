<div *ngIf="releaseLoaded" class="release-single-body">
	<a style="display:block;" routerLink="/dashboard/releases" routerLinkActive="true">< Back to Releases</a>
	<br>
	<div class="release-info">
		<div class="release-image">
			<img src="/assets/images/{{release['album-cover']}}" />
			<span class="release-status {{ release.status }}">{{ release.status | uppercase }}</span>
		</div>
		<div class="release-details">
			<h1 class="release-title">{{release.title}}</h1>
			<p class="release-artist">{{release.artist}}</p>
			<p class="release-announcement-date"><b>Announcement Date: </b>{{ getFormattedDate() }}</p>
			<p class="release-slug"><b>URL Slug: </b>{{ release.slug }}</p>
			<a target="_blank" routerLink="/releases/{{release.slug}}">View release ></a>
		</div>
	</div>
	<hr>
	<div class="release-links">
		<h2 class="section-title">Release Links</h2>
		<table class="release-link-table">
			<tr>
				<td width="35%">
					<app-store-lookup
						*ngIf="showAddNew"
						(selected)="onStoreSelected($event)"
						[modalId]="new-store-creator"
						[(stores)]="stores">
					</app-store-lookup>
				</td>
				<td>
					<input [(ngModel)]="newStoreLink" #nsl="ngModel" [pattern]="'^(http|https)://.*$'" name="newUrl" style="width:100%; border:none; background:#ebebeb; height:40px">
					<div *ngIf="nsl.errors">
						<small class="error-message" *ngIf="nsl.errors.pattern">URL must begin with http:// or https://</small>
					</div>
				</td>

				<td>
					<button
					class="btn small" (click)="addReleaseLink()"
					[disabled]="addingReleaseLink || !newStore || !newStoreLink || nsl.errors">
						Add
					</button>
				</td>
			</tr>
			<tr
				app-release-link-row
				*ngFor="let link of release.links let index = index;"
				(saved)="onSavedLink(link)"
				[(stores)]="stores"
				[(link)]="release.links[index]"
			></tr>
		</table>

	</div>
</div>
