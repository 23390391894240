<td>
	<div *ngIf="!link.editing"> {{link.name}} ({{link.city}}, {{link.country_code === "US" ? link.region : link.country_code }})</div>
	<div *ngIf="link.editing">
		<app-store-lookup
			(selected)="onStoreSelected($event)"
			[modalId]="'store-creator_'+link.id"
			[(storeId)]="link.store_id"
			[(stores)]="stores">
		</app-store-lookup>
	</div>
</td>
<td>
	<div *ngIf="!link.editing">{{link.release_link}}</div>
	<div *ngIf="link.editing">
		<input [(ngModel)]="link.release_link" #nsl="ngModel" [pattern]="'^(http|https)://.*$'" name="newUrl" style="width:100%; border:none; background:#ebebeb; height:40px">
		<div *ngIf="nsl.errors">
			<small class="error-message" *ngIf="nsl.errors.pattern">URL must begin with http:// or https://</small>
		</div>
	</div>
</td>
<td>
	<button *ngIf="!link.editing" class='btn small' (click)="link.editing=true">Edit</button>
	<button *ngIf="link.editing" class='btn small' (click)="saveLink()">Save</button>
</td>
