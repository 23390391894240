import { Component, OnInit } from '@angular/core';
import { BindieApiService } from '../../bindie-api.service';

@Component({
  selector: 'app-dash-releases-page',
  templateUrl: './dash-releases-page.component.html',
  styleUrls: ['./dash-releases-page.component.scss']
})
export class DashReleasesPageComponent implements OnInit {

  constructor(private biApi: BindieApiService) {}
  releases=[];

  ngOnInit() {
	  this.biApi.getMyReleases().subscribe(response => {this.releases = response.reverse();});
  }
}
