import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: '[app-release-link-row]',
  templateUrl: './release-link-row.component.html',
  styleUrls: ['./release-link-row.component.scss']
})
export class ReleaseLinkRowComponent implements OnInit {
	@Input() stores: any[];
	@Input() link: any;
	@Output() saved: EventEmitter<any> = new EventEmitter();

	constructor() { }

	ngOnInit(): void {}

	saveLink(){
		this.saved.emit(this.link);
		this.link.editing=false;
	}
	onStoreSelected(store){
		this.link.store_id = store.id;
		this.link.name = store.name;
		this.link.city = store.city;
		this.link.street = store.street;
		this.link.region = store.region;
		this.link.country_code = store.country_code;
		this.link.latlng=store.latlng;
	}

}
