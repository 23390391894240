<div>
	<ng-select
		[loading]="loading"
		placeholder="Select a Store"
		[(ngModel)]="store"
		[typeahead]="input$"
		bindLabel="label"
		[items]="storesBuffer"
		[virtualScroll]="true"
		[typeahead]="input$"
		(change)="onStoreChanged($event)"
		(scrollToEnd)="fetchMore(select.filterValue)"
		#select
	>
		<ng-template ng-footer-tmp>
			<a class="create-new" (click)="openStoreCreator()">Create New Store +</a>
		</ng-template>
	</ng-select>
	<app-modal id="store-creator-modal-{{modalId}}">
	    <app-store-creator (created)="onStoreCreated($event)" ></app-store-creator>
	</app-modal>
</div>
