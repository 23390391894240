import {Component, Input, OnInit} from '@angular/core';
import {Campaign} from '../campaign';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() message?: string;
  @Input() identifier? :string;
	@Input() color? :string;
  @Input() show: boolean;

  constructor() { }

  ngOnInit() {
    if (!this.color) this.color = "#E11A1A";
    if(!this.message) this.message = "Loading...";
  }

}
