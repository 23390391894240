import { Injectable } from '@angular/core';
import {User} from './user';


@Injectable()
export class UserService {
	constructor() {

	}
	user:User;

	set(user:User):void{
		this.user = user;
	};

	get(key?){
		if(!this.user) return false;
		return key ? this.user[key] : this.user;
	};

	clear():void{
		this.user = null;
	}
}
