import { Component, ElementRef, NgZone, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import { FormControl } from "@angular/forms";
import { MapsAPILoader } from '@agm/core';
import { BindieApiService} from '../bindie-api.service';

declare var google;


@Component({
  selector: 'app-store-creator',
  templateUrl: './store-creator.component.html',
  styleUrls: ['./store-creator.component.scss']
})
export class StoreCreatorComponent implements OnInit {
	@Output() created: EventEmitter<any> = new EventEmitter();
	public latitude: number;
	public longitude: number;
	public searchControl: FormControl;
	public zoom: number;
	public storeName: String;
	public storeLocation:Object;
	@ViewChild("search")
	public searchElementRef: ElementRef;
	public placesSearched: boolean;
	public loading: boolean;
	constructor(
		private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
		private biApi: BindieApiService,
	) {}

	ngOnInit() {
		//set google maps defaults
		this.zoom = 4;
		this.latitude = 39.8282;
		this.longitude = -98.5795;
		this.placesSearched=false;
		this.loading=false;
		//create search FormControl
		this.searchControl = new FormControl();

		//set current position
		this.setCurrentPosition();

		//load Places Autocomplete]
		this.mapsAPILoader.load().then(() => {
			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
				// Get all types.. types: ["establishment", "address"]
			});
			autocomplete.addListener("place_changed", () => {
				this.placesSearched = true;
				this.ngZone.run(() => {
					//get the place result
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();
					//verify result
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}
					//set latitude, longitude and zoom
					this.latitude = place.geometry.location.lat();
					this.longitude = place.geometry.location.lng();
					this.storeLocation = this.getLocationDetails(place);
					this.zoom = 12;
				});
			});
		});
	}

	createStore(){
		this.loading=true;
		const storeDetails = {
			name:this.storeName,
			...this.storeLocation
		}
		this.biApi.createStore(storeDetails).subscribe(result => {
			this.loading=false;
			this.storeName=null;
			this.storeLocation=null;
			this.created.emit(result);
		});
	}

	private getLocationPart(part, returnKey, addressComponents){
		let matches;
		if(Array.isArray(part)){
			matches = addressComponents.filter(comp => part.some(word => ~comp.types.indexOf(word)));
		} else {
			matches = addressComponents.filter(comp => ~comp.types.indexOf(part));
		}
		return (!matches || !matches.length) ? null : matches[0][returnKey];
	}
	private getLocationDetails(place){
		console.log("GETTING LOC DETAILS");
		console.log(place);
		let city = this.getLocationPart(["locality", "colloquial_area"], "short_name", place.address_components);
		if(!city) city = this.getLocationPart(["postal_town"], "short_name", place.address_components);
		console.log(city);
		const region = this.getLocationPart("administrative_area_level_1", "short_name", place.address_components);
		const country_code = this.getLocationPart("country", "short_name", place.address_components);
		let street = "a"+place.adr_address;
		street = street.split('<span class="street-address">')[1];
		street = street.split('</span>')[0];
		const latlng = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;
		return {city,country_code,street,latlng,region};
	}

	private setCurrentPosition() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.latitude = position.coords.latitude;
				this.longitude = position.coords.longitude;
				this.zoom = 12;
			});
		}
	}

}
