import { Injectable } from '@angular/core';
import { Campaign } from './campaign';
import { Observable } from 'rxjs';

@Injectable()
export class CampaignService {

	constructor() {}


  private find_distance(lat1 : number, lon1: number, lat2: number, lon2: number, unit? : string) : number {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") {
      dist = dist * 1.609344
    }
    if (unit == "N") {
      dist = dist * 0.8684
    }
    return dist
  };

  private sortCampaigns(campaignData: Campaign[], position): Campaign[] {
    for (var i = 0; i < campaignData.length; i++) {

      let latlng = campaignData[i].latlng;

      if (latlng){

          let latlng = campaignData[i].latlng.split(','),
          lat = parseFloat(latlng[0]),
          lng = parseFloat(latlng[1]),
          clat = position.coords.latitude,
          clng = position.coords.longitude,
          distance = this.find_distance(clat, clng, lat, lng, "");
        campaignData[i].distance_from_client = distance;
      }
      else {
        campaignData[i].distance_from_client = 99999999999;
      }
    }
    campaignData.sort((a, b) => {
      return (a.distance_from_client > b.distance_from_client) ? 1 : ((b.distance_from_client > a.distance_from_client) ? -1 : 0);
    });
    return campaignData;
  }

  distanceSort(campaignData: Campaign[]) : Observable<any>{
    return Observable.create(observer => {
      if(window.navigator && window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            let sortedCampaigns = this.sortCampaigns(campaignData, position);
            observer.next(sortedCampaigns);
            observer.complete();
          },
          (error) => {
            //Most likely user denied access...
            console.log(error.message);
            observer.error(campaignData);
          },
            {enableHighAccuracy: true, timeout: 20000, maximumAge: 10000}
        );
      } else {
        observer.error('Unsupported Browser');
      }
    });
  };




}
