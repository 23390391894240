<div class="page-container">
	<div class="container">
		<div class="page-block">
			<h2 class="page-block-header">LOGIN</h2>
			<div class="page-block-content">
				<form #f="ngForm" (ngSubmit)="login(f)">
					<div class="form-group">
						<input name="email" placeholder="email" type="text" class="form-control" ngModel required>
					</div>
					<div class="form-group">
						<input type="password" placeholder="password" class="form-control"  name="password" ngModel required>
					</div>

					<button type="submit"  class="btn small">Login</button>
					<!-- <button type="submit"  class="btn small" *ngIf="!authService.isLoggedIn()">Login</button> -->
					<!-- <button (click)="logout()" class="btn small"  *ngIf="authService.isLoggedIn()">Logout</button> -->
					<p>{{message}}</p>
				</form>
			</div>
		</div>
	</div>
</div>
