<nav id="sidebar" class="">
  <ul class="navbar-nav">
    <li class="nav-item active">
      <a routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
    </li>
    <li class="nav-item">
			<a routerLink="/dashboard/users" routerLinkActive="active">Users</a>
    </li>
    <li class="nav-item">
      <a routerLink="/dashboard/releases" routerLinkActive="active">Releases</a>
    </li>
  </ul>
  <button (click)="logout()" class="logout-btn btn small"  *ngIf="authService.isLoggedIn()">Logout</button>
</nav>
