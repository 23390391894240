import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import {TokenService} from './token.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
@Injectable()
export class BindieApiService {
	constructor( private http: HttpClient, private token: TokenService, private router: Router) {
		console.log("ENV!!");
		console.log(environment);
	}

	apiUrl = environment.apiUrl;

	apiAuthFailure(){
		this.router.navigate(['/login']);
	}

	private getReqConfig(): Object{
		const headers = {'Content-Type': 'application/json'};
		if(!!this.token.get()) headers['x-auth-token'] = this.token.get();
		return {'headers': new HttpHeaders(headers)};
	}

	private post(path:string, data?:any): Observable<any> {
		return this.http.post<any>(`${this.apiUrl}${path}`, data, this.getReqConfig())
		.pipe(map(r=>r.data))
		.pipe(catchError(err => {
			if( err.status === 401 ) {
				this.apiAuthFailure();
			}
			console.log('caught rethrown error, providing fallback value', err);
			throw err;
		}));
	}

	private get(path:string): Observable<any> {
		return this.http.get<any>(`${this.apiUrl}${path}`, this.getReqConfig())
		.pipe(map(r=>r.data))
		.pipe(catchError(err => {
			if( err.status === 401 ) {
				this.apiAuthFailure();
			}
			console.log('caught rethrown error, providing fallback value', err);
			throw err;
		}));
	}

	getStores(): Observable<any> {
		return this.get(`/stores`);
	}
	createStore(storeDetails): Observable<any> {
		return this.post(`/stores`, storeDetails);
	}

	login(username:string, password:string): Observable<any> {
		return this.post(`/login`, {email:username, password:password});
	}

	getRelease(slug:string): Observable<any> {
		return this.get(`/release/${slug}`);
	}

	getLabel(labelSlug:string):Observable<any>{
		return this.get(`/label.php?slug=${labelSlug}`);
	}

	getMe(): Observable<any> {
		return this.get(`/me`);
	}
	getMyReleases(): Observable<any> {
		return this.get(`/my/releases`);
	}
	getMyRelease(id:number): Observable<any> {
		return this.get(`/my/release/${id}`);
	}

	addReleaseLink(release_id:number, store_id:number, url:string): Observable<any> {
		return this.post(`/release/${release_id}/link`, {store_id, url});
	}
	editReleaseLink(release_id:number, link_id:number, edits): Observable<any> {
		return this.post(`/release/${release_id}/link/${link_id}`, edits);
	}

	deleteReleaseLink(release_id:number, link_id:number): Observable<any> {
		return this.post(`/release/${release_id}/delete-link`, {link_id: link_id});
	}


}
