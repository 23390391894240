<!--main loading div-->
<app-loader identifier="release-loader" message="Getting Label Data" [show]="loading"></app-loader>

<div class="page-container" *ngIf="!loading">
  <div class="container">

    <!-- Not Auth'd message-->
    <div *ngIf="!userIsAuthdForLabel()" class="error">
      <h1>Sorry!</h1>
      <p>You are not authorized to submit releases for this label.</p>
    </div>

    <div *ngIf="userIsAuthdForLabel()">

      <!--No Label Found...-->
      <div *ngIf="!label">
        <h1>Sorry!</h1>
        <p>We don't have any record of this label.</p>
      </div>

      <!--Label Release Submission Form-->
      <div *ngIf="label">
        <img *ngIf="label.logo" class="label-logo" [src]="'assets/images/'+label.logo">
        <div class="page-block">
          <h2 class="page-block-header">Release Submission</h2>
          <div class="page-block-content">
          <p>Fill out the form below with details about the release and pre-order. Please include any indie exclusive information. We will then circulate the release information to our participating stores to see if they are interested in taking part.</p>

          <form #f="ngForm">
            <div class="cft-g form-grid">
              <div class="cft-gi">
                <div class="input-container" [ngClass]="{'active' : activeInput(f, 'release_title')}">
                  <input ngModel type="text" id="release_title" name="release_title">
                  <label for="release_title">Release Title</label>
                </div>
              </div><!--
              --><div class="cft-gi">
                <div class="input-container" [ngClass]="{'active' : activeInput(f, 'release_artist')}">
                  <input ngModel type="text" id="release_artist" name="release_artist">
                  <label for="release_artist">Release Artist</label>
                </div>
              </div><!--<
              --><div class="cft-gi">
                <div class="input-container" [ngClass]="{'active' :  dates.release_date}">
                  <material-datepicker placeholder="" [(date)]="dates.release_date" altInputStyle="false" fontFamily='"Nexa-Regular", sans-serif'></material-datepicker>
                  <label>Release Date</label>
                </div>
              </div><!--
              --><div class="cft-gi">
              <div class="input-container" [ngClass]="{'active' :  dates.preorder_announcement_date}">
                <material-datepicker placeholder="" [(date)]="dates.preorder_announcement_date" altInputStyle="false" fontFamily='"Nexa-Regular", sans-serif'></material-datepicker>
                <label>Pre-Order Announcement Date</label>
              </div>
            </div><!--
            --><div class="cft-gi">
              <div class="input-container" [ngClass]="{'active' : activeInput(f, 'store_announcement_date')}">
                <input ngModel type="text" id="store_announcement_date" name="store_announcement_date">
                <label for="store_announcement_date">Store Announcement Date</label>
              </div>
            </div>


            </div>



          </form>
        </div>
        </div>
      </div>

  </div>


  </div>
</div>