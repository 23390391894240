import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { BindieApiService } from './bindie-api.service';
import { ReleasesComponent } from './releases/releases.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { CampaignService} from "./campaign.service";
import { LoaderComponent } from './loader/loader.component';
import { ReleaseSubmissionComponent } from './release-submission/release-submission.component';
import { CampaignSubmissionComponent } from './campaign-submission/campaign-submission.component';
import { LoginComponent } from './login/login.component';
import { LoginRoutingModule } from './login-routing.module';
import { InputDataDirective } from './input-data.directive';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UserService} from './user.service';

import {TokenService} from './token.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DashpageComponent } from './dashpage/dashpage.component';
import { DashUsersPageComponent } from './dashboard/dash-users-page/dash-users-page.component';
import { DashReleasesPageComponent } from './dashboard/dash-releases-page/dash-releases-page.component';
import { DashReleaseSingleComponent } from './dashboard/dash-release-single/dash-release-single.component';
import { StoreCreatorComponent } from './store-creator/store-creator.component';

import { AgmCoreModule } from '@agm/core';
import { ModalComponent } from './modal/modal.component';
import { ReleaseLinkRowComponent } from './dashboard/dash-release-single/release-link-row/release-link-row.component';
import { StoreLookupComponent } from './dashboard/dash-release-single/store-lookup/store-lookup.component';

@NgModule({
  declarations: [
    AppComponent,
    ReleasesComponent,
    HomeComponent,
    CampaignsComponent,
    LoaderComponent,
    ReleaseSubmissionComponent,
    CampaignSubmissionComponent,
    LoginComponent,
    InputDataDirective,
    SidebarComponent,
    DashpageComponent,
    DashUsersPageComponent,
    DashReleasesPageComponent,
    DashReleaseSingleComponent,
    StoreCreatorComponent,
    ModalComponent,
    ReleaseLinkRowComponent,
    StoreLookupComponent,
  ],
  imports: [
	BrowserAnimationsModule,
	BrowserModule,
	FormsModule,
	ReactiveFormsModule,
	NgSelectModule,
	AppRoutingModule,
	HttpClientModule,
	LoginRoutingModule,
	AutocompleteLibModule,
	CommonModule,
	AgmCoreModule.forRoot(  {
      apiKey: 'AIzaSyDXCMwVGhco6faGrpU1nX-nJHLTqx45q3E',
	   libraries: ["places"]
   }),
  ],
  providers: [
    BindieApiService,
    CampaignService,
    UserService,
	TokenService,
    Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
