

<div class="{{identifier}}">
  <div *ngIf="show" class="loading-message">
    <div class="spinner">
      <div [ngStyle]="{'background': color}" class="rect1"></div><!--
    --><div [ngStyle]="{'background': color}" class="rect2"></div><!--
    --><div [ngStyle]="{'background': color}" class="rect3"></div><!--
    --><div [ngStyle]="{'background': color}" class="rect4"></div><!--
    --><div [ngStyle]="{'background': color}" class="rect5"></div>
    </div> {{message}}
  </div>
</div>