import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReleasesComponent }    from './releases/releases.component';
import { HomeComponent }    from './home/home.component';
import {ReleaseSubmissionComponent} from './release-submission/release-submission.component';
import {CampaignSubmissionComponent} from './campaign-submission/campaign-submission.component';
import {AuthGuard} from './auth-guard.service';
import { DashpageComponent} from './dashpage/dashpage.component';
import { DashUsersPageComponent } from './dashboard/dash-users-page/dash-users-page.component';
import { DashReleasesPageComponent } from './dashboard/dash-releases-page/dash-releases-page.component';
import { DashReleaseSingleComponent } from './dashboard/dash-release-single/dash-release-single.component';

const SUPER_ADMIN_ROLE = 1;
const ADMIN_ROLE = 2;

const dashboardRoutes = [
	{
		path: 'releases',
		pathMatch:'full',
		component: DashReleasesPageComponent,
	},
	{
		path: 'releases/:id',
		pathMatch:'full',
		component: DashReleaseSingleComponent,
	},
	{	path: 'users',
		pathMatch:'full',
		canActivate: [AuthGuard],
		data:{allowedRoles:[SUPER_ADMIN_ROLE]},
		component: DashUsersPageComponent
	},
];

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'releases/:slug', component: ReleasesComponent },
    {
        path: 'release-submission/:label',
        component: ReleaseSubmissionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'campaign-submission/:label',
        component: CampaignSubmissionComponent,
        canActivate: [AuthGuard],
    },
		{
        path: 'dashboard',
		pathMatch:'prefix',
        component: DashpageComponent,
        canActivate: [AuthGuard],
			children: dashboardRoutes
    }
];


@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {}
