import { Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';
import {User} from '../user';
import {ActivatedRoute} from '@angular/router';
import {BindieApiService} from '../bindie-api.service';

@Component({
  selector: 'app-release-submission',
  templateUrl: './release-submission.component.html',
  styleUrls: ['./release-submission.component.scss']
})
export class ReleaseSubmissionComponent implements OnInit {
  
  constructor(private biApi:BindieApiService, private route:ActivatedRoute, private userService:UserService) {}

  slug:string;
	user:User;
	loading:boolean = true;
	loaderColor:string;
	
	label = null;
	dates = {};
	
	dateActive(f, data):void{
		f.value[data] = "";
	}
	userIsAuthdForLabel():boolean{
		return this.user && (this.user.labels.indexOf(this.slug) > -1 || this.user.role > 2);
	}
	
	activeInput(f, input):boolean{
		return f.value[input] && f.value[input].length;
	};
	
  ngOnInit() {
	  
  	//this route is Auth'd so if we get this far, we know we're logged in...
    this.user = this.userService.get();
    this.slug = this.route.snapshot.paramMap.get('label');
    this.loaderColor = this.slug == "umo-sex-and-food" ? "" : "";
    //Check to see if user is authorized for label...
	  
		if (this.userIsAuthdForLabel()){
			//If so, get label data...
		  this.biApi.getLabel(this.slug)
        .subscribe(data =>{
        	this.loading=false;
		      this.label = data;
		  });
		}else{
			this.loading=false;
		}
		
		
	 
  }
  
  

}
