import { Injectable }       from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
}                           from '@angular/router';
import { AuthService }      from './auth.service';
import { UserService }      from './user.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private user: UserService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		let url: string = state.url;
		let allowed = this.checkLogin(url);
		console.log("canActivate");
		console.log(route.data);
		if(route.data && route.data.allowedRoles){
			console.log("ABOUT TO CHEK ROLE");
			allowed = this.checkRoles(route.data.allowedRoles);
		}
		return allowed;
	}

	checkRoleFailed(){
		this.router.navigate(['/login']);
	}

	checkRoles(allowedRoles){
		if(!allowedRoles.length) return false;
		const role = this.user.get('role_id');
		if(!role) {
			//TODO: navigate to unauthorized page?
			this.checkRoleFailed();
			return false;
		}
		if(allowedRoles.indexOf(role) < 0){
			//TODO: navigate to unauthorized page?
			this.checkRoleFailed();
			return false;
		}
		return true;
	}

	checkLogin(url: string): boolean {
		console.log("CHECKING LOGIN...");

		if (this.authService.isLoggedIn()) { return true; }
		this.authService.redirectUrl = url;
		this.router.navigate(['/login']);
		return false;
	}
}
