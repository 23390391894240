import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {
	authToken = '';
	constructor() {
		this.authToken = localStorage.getItem("authToken");
	}

	set(token){
		if(token){
			localStorage.setItem("authToken", token);
			this.authToken = token;
		}
		else this.clear();
	}

	clear(){
		localStorage.removeItem("authToken");
		this.authToken = "";
	}

	get() {
		return this.authToken;
	}
}
