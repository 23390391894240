import {Directive, Input} from '@angular/core';
import {Campaign} from './campaign';

@Directive({
  selector: '[appInputData]'
})
export class InputDataDirective {
	@Input() name:string;
	@Input() label:string;
  constructor() { }

}
