import { Injectable } from '@angular/core';
import * as auth0 from 'auth0-js';
import { environment } from './../environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import {UserService} from './user.service';
import {TokenService} from './token.service';
import {BindieApiService} from './bindie-api.service'
import { catchError, map, tap } from 'rxjs/operators';
(window as any).global = window;

@Injectable()
export class AuthService {

	expiresAt: number;
	userProfile: any;
	authenticated: boolean;
	redirectUrl: string;
	loggedIn : boolean;

	constructor(private router: Router, private user: UserService, private biApi: BindieApiService, private token: TokenService) {
		this.authenticated = localStorage.getItem("loggedIn") == '1';
	}

	handleLoginCallback() {

	}

	getUserInfo(authResult) {
	// Use access token to retrieve user's profile and set session
	//get user info... tehn...
	//this._setSession(authResult, profile);

	}

	private _setSession(data) {
		// Save authentication data and update login status subject
		this.authenticated = true;
		this.token.set(data.auth_token);
		this.user.set(data.user);
		localStorage.setItem("loggedIn", '1');
	}

	logout() {
		this.authenticated = false;
		localStorage.setItem("loggedIn", '');
		this.token.clear();
		this.user.clear();
	}

	isLoggedIn(): boolean {
		return this.authenticated && !!this.token.get();
	}

	login(username: string, password: string): Observable<any> {
		var loggedIn = false;
		return this.biApi.login(username, password).pipe(
			map((data) => {
				this._setSession(data);
				return data;
			})
		);
	}

}
