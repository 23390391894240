<app-loader identifier="release-loader" message="Getting Release Data" [color]="loaderColor" [show]="!release && !errorMessage"></app-loader>

<h3 *ngIf="errorMessage" class="errorMessage">{{errorMessage}}</h3>

<div *ngIf="release && !errorMessage" class="bg-image" [ngStyle]="getBackground()">

    <div class="container {{slug}}">
      <section class="primary">
        <header>
          <h1>Thanks for supporting local record stores!</h1>
        </header>

        <div class="inner">
          <div class="cft-g">
            <div class="cft-gi">

                <!--SEX & FOOD STYLES-->
                <style *ngIf="slug == 'umo-sex-and-food'">

                   .umo-sex-and-food .closest-store-container em{ color:#217182; }
                   .umo-sex-and-food  .btn, .umo-sex-and-food  .btn-secondary, .umo-sex-and-food  a.btn-secondary, .umo-sex-and-food  a.btn, .umo-sex-and-food  a.btn-secondary {
                       background: #da8ecf;
                       color:#fff;
                   }
                   .umo-sex-and-food  .btn:hover, .umo-sex-and-food .btn-secondary:hover, .umo-sex-and-food  a.btn:hover, .umo-sex-and-food a.btn-secondary:hover{
                       background: #c77bbc;
                       color:#fff;
                   }
                   .umo-sex-and-food   a{
                        color:#da8ecf;
                    }
                   .umo-sex-and-food  a:hover{
                        color:#c77bbc
                    }
                    .umo-sex-and-food  .campaign-list a{
                        color:#da8ecf;
                    }
                   .umo-sex-and-food  .campaign-list a:hover{
                       color:#c77bbc
                   }
                </style>

                <!--Release Details-->
              <div class="release">
                <img [src]="'assets/images/'+release['album-cover']">
                <div class="release-details">
                  <h3>{{release.artist}}</h3>
                  <h4>{{release.title}} <span class="indie-offering" *ngIf="release.indie_offering">{{release.indie_offering}}</span></h4>
                </div>
              </div>

            </div><!--
            --><div class="cft-gi">
              <div class="closest-store-container">
	              <p *ngIf="slug == 'twin-peaks-lookout-low'">We love independent record stores and believe in supporting them. Pre-order Twin Peaks' new record in one of these stores and pick up a limited edition "Dance Through It" 7" pre-order gift, while supplies last.</p>

	            <p *ngIf="slug == 'better-oblivion-community-center'">We love independent record stores and believe in supporting them. If you enable your location services, we’ll find your closest participating store so you can pick up Better Oblivion Community Center at a great local shop.</p>

                <p *ngIf="slug == 'whitney-forever-turned-around'">We love independent record stores and believe in supporting them. Enable your location services to find the closest participating store and pre-order Whitney’s new record at a great local shop.</p>

                <p *ngIf="!closestStore && slug != 'better-oblivion-community-center' && slug != 'twin-peaks-lookout-low' && slug != 'whitney-forever-turned-around'" class="intro location-check">We love independent record stores and believe in supporting them. Enable your location services to find the closest participating store and pre-order {{release.title}} by {{release.artist}} <span *ngIf="release.indie_offering">({{release.indie_offering}})</span> at a great local shop.</p>

                <!--Closest Store info-->
                <div *ngIf="closestStore" class="closest-store">
                    <p class="intro">Your closest participating store is:</p>
                    <p class="intro closest-store-details"><em>{{closestStore.name}}</em><br>
                        in <em>{{closestStore.city}},
                            <span *ngIf="closestStore && closestStore.state">{{closestStore.state}}</span>
                            <span *ngIf="!closestStore.state">{{closestStore.country_code}}</span>
                        </em>.</p>
                    <a *ngIf="closestStore.release_link" class="btn" href="{{closestStore.release_link}}">
                        Buy at {{closestStore.name}}
                    </a>
                </div>

                <div *ngIf="!processingCampaigns && !closestStore">
                    <a *ngIf="campaignsProcessed" href="https://support.google.com/chrome/answer/142065?hl=en" target="_blank" class="enable-location-services">Learn how to enable location services</a>
                    <a *ngIf="!campaignsProcessed" (click)="processCampaigns(campaignData)" class="btn find-stores-secondary">Find My Closest Record Store</a>
                    <p>Or choose  from a participating store in the list below</p>
                </div>

                  <app-loader [color]="loaderColor" identifier="campaign-loader" message="Finding your closest record store" [show]="processingCampaigns"></app-loader>


              </div>

            </div>

          </div>
        </div>
      </section>

        <section class="secondary"  *ngIf="campaignData?.length > 0">

            <header>
              <h2>Pre-Order From Any Participating Store</h2>
            </header>

            <div class="campaign-list">
                <div class="cft-g">
                    <div *ngFor="let campaign of campaignData" class="cft-gi">
                        <h3>{{campaign.name}}</h3>
                        <h4 *ngIf="campaign.country_code && campaign.city">{{campaign.city}},
	                        <span *ngIf="campaign.country_code === 'US'">{{campaign.region}}</span>
	                        <span *ngIf="campaign.country_code !== 'US'">{{campaign.country_code}}</span>
                        </h4>
                        <a *ngIf="campaign.release_link" [href]="campaign.release_link">Buy at {{campaign.name}}</a>
                    </div>
                </div>
            </div>

        </section>


        <div class="copyright">
			<!--If you don't see your local record store listed, or would like to participate, please <a href="mailto:info@localrecordstores.com">email us</a><br>-->
			© {{year}} localrecordstores.com
		</div>
    </div>
</div>
